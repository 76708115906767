import SecureStorage from "secure-web-storage";

var CryptoJS = require("crypto-js");
var SECRET_KEY = 'my secret key';
var secureStorage = new SecureStorage(localStorage, {
  hash: function hash(key) {
      key = CryptoJS.SHA256(key, SECRET_KEY);

      return key.toString();
  },
  encrypt: function encrypt(data) {
      data = CryptoJS.AES.encrypt(data, SECRET_KEY);

      data = data.toString();

      return data;
  },
  decrypt: function decrypt(data) {
      data = CryptoJS.AES.decrypt(data, SECRET_KEY);

      data = data.toString(CryptoJS.enc.Utf8);

      return data;
  }
});


export default {
  async login(context, payload) {
    return context.dispatch('auth', {
      ...payload,
      mode: 'login'
    });
  },
  async signup(context, payload) {
    const response = await fetch('https://'+context.rootState.checkApiAdress+'/api/v1/users/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username : payload.username,
        password : payload.password,
        realname : payload.realname ,
        phonenumber : payload.phonenumber,
      })
    });
    const responseData = await response.json();
    console.log(responseData);
    context.dispatch('auth',{username : payload.username, password : payload.password});
  },
  async auth(context, payload) {
    console.log(context.rootState.checkApiAdress);
    const response = await fetch('https://'+context.rootState.checkApiAdress+'/api/v1/users/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username : payload.username,
        password : payload.password
      })
    });
    const responseData = await response.json();

    if (!response.ok) {
      throw 400;
    }
    secureStorage.setItem('UserData', JSON.stringify(responseData.user));
    localStorage.setItem('token', responseData.access);
    context.commit('setToken', responseData.access);
    context.commit('setUserData', responseData.user);
  },
  tryLogin(context) {
    const token = localStorage.getItem('token');
    const userData = secureStorage.getItem('UserData');
    if (token) {
      context.commit('setToken', { token: token, });
      context.commit('setUserData', JSON.parse(userData));
      localStorage.removeItem('UserData');
    }
  },
  logout(context) {
    localStorage.removeItem('token');
    context.commit('setToken', {
      token: null,
    });
  },
  signupTempEmail(context, payload) { 
    console.log('Action signupTempEmail : ' + payload);
    context.commit('saveSignupEmail', payload);
  },
  signupTempPassword(context, payload) { 
    console.log('Action signupTempPassword : ' + payload);
    context.commit('saveSignupPassword', payload);
  },
  signupTempPhone(context, payload) { 
    console.log('Action signupTempPhone : ' + payload);
    context.commit('saveSignupPhone', payload);
  },
  saveUserData(context, payload){
    secureStorage.setItem('UserData', JSON.stringify(payload.user));
    localStorage.setItem('token', payload.access);
    context.commit('setToken', payload.access);
    context.commit('setUserData', payload.user);
  },
  resaveUserData(context, payload){
    console.log('reloaded!')
    console.log(payload);
    secureStorage.setItem('UserData', JSON.stringify(payload));
  }
}