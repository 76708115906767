<template>
  <footer>
    <div class="autoScale">
      <router-link to="/consumer/home" @click="updatePaths('/consumer/home')">
        <HomeIcon :activationValue="this.ActivationGroupValue[0]"></HomeIcon>
      </router-link>

      <router-link to="/consumer/requestlist" @click="updatePaths('/consumer/requestlist')">
        <RequestStatsIcon :activationValue="this.ActivationGroupValue[1]"></RequestStatsIcon>
      </router-link>

      <router-link to="/consumer/confirmlist" @click="updatePaths('/consumer/confirmlist')">
        <ReservationListIcon :activationValue="this.ActivationGroupValue[2]"></ReservationListIcon>
      </router-link>

      <router-link to="/consumer/mypage" @click="updatePaths('/consumer/mypage')">
        <MyPageIcon :activationValue="this.ActivationGroupValue[3]"></MyPageIcon>
      </router-link>
    </div>
  </footer>
  
</template>

<script>
import HomeIcon from '@/pages/consumer/footerbar/components/icon_1_Home.vue';
import RequestStatsIcon from '@/pages/consumer/footerbar/components/icon_2_RequestStats.vue'; // 오타 수정
import ReservationListIcon from '@/pages/consumer/footerbar/components/icon_3_ReservationList.vue'; // 경로 수정
import MyPageIcon from '@/pages/consumer/footerbar/components/icon_4_Mypage.vue';
export default {
  components:{HomeIcon,
  RequestStatsIcon,
  ReservationListIcon,
  MyPageIcon},
  data() {
    return {
      ActivationGroupValue: [true, false, false, false],
      urlList: [
        '/consumer/home',
        '/consumer/requestlist',
        '/consumer/confirmlist',
        '/consumer/mypage',
      ],
      tempdata: "temp"
    }
  },
  props:{
    updatePaths: {
      required: true
    },
    goPath: {
      goPath: String // 여기에 goPath 속성 추가
    }
  },
  watch: {
    goPath(newValue) {
      const index = this.urlList.findIndex(url => url === newValue);
      this.ActivationGroupValue = this.ActivationGroupValue.map((_, i) => i === index);
    },
  },
}
</script>

<style lang="scss">@import "@/pages/consumer/footerbar/scss/MainCore.scss";</style>