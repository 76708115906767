import { createApp } from 'vue';
import { createNaverMap } from "vue3-naver-maps";

import router from './router.js';
import store from './store/index.js';
import App from './App.vue';

import BaseButton from './components/Interface/BaseButton.vue'
import GlobalButton from './components/Interface/Base-Button.vue'
import BaseRegion from './components/Interface/BaseRegion.vue'
import BaseWhiteCard from './components/Interface/BaseWhiteCard.vue'
import BaseGrayCard from './components/Interface/BaseGrayCard.vue'





const app = createApp(App)

app.use(router);
app.use(store);
app.use(createNaverMap, {
    clientId: "vn3rnqnfu3", // Required
    category: "ncp", // Optional
    subModules: [], // Optional, "panorama" | "geocoder" | "drawing" | "visualization"
  })

app.component('base-white-card', BaseWhiteCard);
app.component('base-gray-card', BaseGrayCard);
app.component('base-button', BaseButton);
app.component('global-button', GlobalButton);
app.component('base-region', BaseRegion);



// import VueGtag from 'vue-gtag'
// app.use(VueGtag, {
//     config: {
//         id: 'G-Z8CDJGQ73X'  // Google Analytics의 Tracking ID
//     }
// });

app.mount('#app');
