export default {
  getRequestList: state => state.requestList.request_list,
  getRequestDetail: state => state.requestDetail,
  getRequestDetailData(state) {
    return state.requestDetail;
  },
  getShopInfo(state) {
    return state.shopInfo;
  },
  
}