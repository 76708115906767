<template>
  <router-view v-slot="{ Component }" class="Viewx">
    <transition :name="dynamicAnimationAssignment" @before-enter="beforeEnter" @after-enter="onAnimationComplete">
      <component :is="Component" :goPath="goPath" />
    </transition>
  </router-view>

  <footerbar :updatePaths="updatePaths" :goPath="readGoPathValue"></footerbar>

  <transition name="modal" appear>
    <div class="alarmPage" v-if="alertModalStatusValue">
      <div class="fix">
        <div class="header">
        <img src="@/assets/svg/big_left_arrow.svg" @click="this.$store.dispatch('global/setAlarmOpenValue', false);">
        </div>
        <div class="g-title">도착한 알림</div>
        <div class="HR"></div>
      </div>
      <div class="LOOPAREA">
        <div v-for="a in this.alarmSocketData" :key="a" class="item">
          {{ a }}
          <!-- <div class="left">
            <img v-if="a.type == 'new_request'" src="@/assets/svg/alarm/airplane.svg" alt="">
            <img v-if="a.type == 'new_bid'" src="@/assets/svg/alarm/airplane.svg" alt="">
            <img v-if="a.type == 'bid_accept_consumer'" src="@/assets/svg/alarm/confirmBid.svg" alt="">
            <img v-if="a.type == 'notice'" src="@/assets/svg/alarm/notice.svg" alt="">
            <img v-if="a.type == 'bid_accept'" src="@/assets/svg/alarm/done.svg" alt="">
            <img v-if="a.type == 'bid_accept_owner'" src="@/assets/svg/alarm/done.svg" alt="">
            <img v-if="a.type == 'bid_rejected'" src="@/assets/svg/alarm/cancel.svg" alt="">
          </div>
          <div class="right">
            <div class="top">
              <span class="status" v-if="a.type == 'new_request'" >새 요청서</span>
              <span class="status" v-if="a.type == 'new_bid'" >새 요청서</span>
              <span class="status" v-if="a.type == 'bid_accept_consumer'" >착찰된 비드</span>
              <span class="status" v-if="a.type == 'notice'" >공지사항</span>
              <span class="status" v-if="a.type == 'bid_accept'" >방문 확정!</span>
              <span class="status" v-if="a.type == 'bid_accept_owner'" >방문 확정!</span>
              <span class="status" v-if="a.type == 'bid_rejected'" >낙찰 거부</span>
              <span class="time">{{ formattedDate(a.timestamp) }}</span>
            </div>
            <div class="bottom">
              {{ a.message }}
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </transition> 

  <transition name="modal" appear>
    <div class="settingPage" v-if="counter">
      <div class="header">
        <img src="@/assets/svg/big_left_arrow.svg" @click="this.$store.dispatch('global/setModalOpenValue', false);">
      </div>
      <div class="title">설정</div>
      <div class="viewMenu">
        <div class="goAlarmSetting">
          <div class="text">알림 설정</div>
          <img src="@/assets/svg/small_right_arrow.svg" alt="">
        </div>
        <div class="viewAppVersion">
          <div class="text">앱 버전</div>
          <span class="ver">{{this.$store.state.version}}</span>
        </div>
      </div>
      <div class="bottomFixMenu">
        <div class="group">
          <div class="logout" @click="openLogoutModal()">로그아웃</div>
          <div class="checkout" @click="showCheckOut = true">회원탈퇴</div> 
        </div>
      </div>
    </div>
  </transition>

  <transition name="modal-background" appear>
    <div class="modalBackground" v-if="showModalValue" @click="disbaleModal()"></div>
  </transition>

  <transition name="modalLogout" appear>
    <div class="signoutModal" v-if="checksignout">
      <div class="view">
        <div class="title">로그아웃 하시겠어요?</div>
        <div class="logoutbutton" @click="handleLogout">로그아웃</div>
      </div>
    </div>
  </transition>

  <transition name="requestAnimation" appear>
    <div id="checkoutModal" v-if="showCheckOut">
      <div>
        <nav>
          <img src="@/assets/svg/big_left_arrow.svg" alt="" @click="showCheckOut = false">
        </nav>
        <div class="header">
          <div class="big">정말로 탈퇴하시겠어요?</div>
          <div class="sub">탈퇴하시면 계정을 다시 되살릴 수 없어요!</div>
        </div>
        <div class="forms">
          <div class="top">탈퇴하는 이유가 무엇인가요?</div>
          <label class="container" v-for="num in 6" :key="num">
            <input type="checkbox" :value="num" v-model="selectedValues" />
            <span class="texts">{{ selectedValuesText[num-1] }}</span>
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="guard">
        <div class="buttonGroup">
          <div class="cancel" @click="showCheckOut = false">취소</div>
          <div class="doCheckout" @click="startCheckOut">탈퇴할래요</div>
        </div>
      </div>
    </div>
  </transition>

  <transition name="requestAnimation" appear>
    <div class="checkoutDoneModal" v-if="checkDone">
      <div class="guard">
        <img src="@/assets/svg/consumer/color_airplane.svg" alt="">
        <div class="title">탈퇴했어요</div>
        <div class="sub">가게나우를 사용해주셔서 감사합니다.</div>
      </div>
      <div class="ButtonGroup">
        <div class="Done" @click="DoneCheckout">확인</div>
      </div>
    </div>
  </transition>

</template>

<script>
import footerbar from '@/pages/consumer/footerbar/MainCore.vue';
export default {
  components:{footerbar},
  created(){
    this.connectWebSocket();
  },
  methods: {
    beforeEnter(){
      window.scrollTo(0, 0);
      document.querySelector("footer").style.position = "absolute";
    },
    onAnimationComplete(){
      document.querySelector("footer").style.position = "fixed";
    },
    updatePaths(path) {
      this.fromPath = this.goPath;
      this.goPath = path;
    },
    openLogoutModal(){
      this.showModalValue =  true;
      this.checksignout = true;
    },
    disbaleModal(){
      this.showModalValue =  false;
      this.checksignout = false;
    },
    connectWebSocket() {
      this.alarmSocket = new WebSocket(`wss://api.gagenow.com/ws/consumer/user-notification/token=${localStorage.getItem('token')}`);
      
      this.alarmSocket.onopen = () => {};

      this.alarmSocket.addEventListener('message', (event) => {
        const messageData = JSON.parse(event.data);
        console.log(messageData);
        this.alarmSocketData = messageData;
      });
    },
    formattedDate(times) {
      const diff = Date.now() - new Date(times).getTime();
      const second = 1000;
      const minute = 60 * second;
      const hour = 60 * minute;
      const day = 24 * hour;
      const week = 7 * day;

      if (diff < minute) {
        return `${Math.floor(diff / second)}초 전`;
      } else if (diff < hour) {
        return `${Math.floor(diff / minute)}분 전`;
      } else if (diff < day) {
        return `${Math.floor(diff / hour)}시간 전`;
      } else if (diff < week) {
        return `${Math.floor(diff / day)}일 전`;
      } else {
        return `${Math.floor(diff / week)}주 전`;
      }
    },
    startCheckOut(){
      fetch('https://api.gagenow.com/api/v1/users/consumer/withdrawal', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "causes" : this.selectedValues
        })
      })
      this.checkDone = true;
      this.$store.dispatch('logout');
    },
    DoneCheckout(){
      this.$router.push('/auth/login')
    },
    handleLogout(){
      this.$store.dispatch('logout')
      this.$router.push('/auth/login')
    }
  },
  data() {
    return {
      footerBarColor: [
        ['#FF7212','#FF7212'],
        ['#CDD0D8','#A0A3AB'],
        ['#CDD0D8','#A0A3AB'],
        ['#CDD0D8','#A0A3AB']
      ],
      alarmSocket: false,
      alarmSocketData: [],
      openedSetting: false,
      previous: false,
      goPath: "",
      fromPath: "",
      dynamicAnimationValue: 'RIGHT',
      showModalValue:false,
      checksignout:false,
      showCheckOut: false,
      footerPathList: [
        '/consumer/home',
        '/consumer/requestlist',
        '/consumer/confirmlist',
        '/consumer/mypage'
      ],
      selectedValues: [],
      selectedValuesText: [
        '앱을 더 이상 사용하지 않아요',
        '서비스 이용 과정이 너무 귀찮아요',
        '사장님이 낙찰확정을 안 해주세요',
        '마음에 드는 비드를 많이 받지 못했어요',
        '어떻게 사용하는지 잘 모르겠어요',
        '기타'
      ],
      checkDone:false
    };
  },
  computed: {
    dynamicAnimationAssignment() {
      if (this.footerPathList.includes(this.goPath)) {
        const goPathNum = this.footerPathList.indexOf(this.goPath);
        const fromPathNum = this.footerPathList.indexOf(this.fromPath);
        if (fromPathNum < goPathNum) {
          console.log('123');
          return 'RIGHT';
        } else {
          console.log('456');
          return 'LEFT';
        }
      } else if (this.goPath.substr(23)) {
        this.switchPrevious();
        return 'RIGHT';
      } else if (this.goPath == '/consumer/make') {
        return 'UP';
      }
      return 'RIGHT';
    },
    Component() {
      return this.$route.meta.layout || this.$route.component
    },
    counter() {
      return this.$store.state.global.modalOpenValue;
    },
    alertModalStatusValue() {
      return this.$store.state.global.alertModalOpenValue;
    },
    readGoPathValue(){
      return this.goPath
    }
  },
  watch: {
    counter() {},
    alertModalStatusValue() {},
    goPath(newValue) {
      this.goPath = newValue;
    }
  }
}
</script>

<style lang="scss" scoped>
.LEFT-enter-active,
.LEFT-leave-active {
  width: 100%;
  position: absolute;
  transition: all 0.5s ease-in-out;
}

.LEFT-enter-from {
  left: -100%;
}

.LEFT-enter-to {
  left: 0%;
}

.RIGHT-enter-active,
.RIGHT-leave-active {
  width: 100%;
  position: absolute;
  transition: all 0.5s ease-in-out;
}

.RIGHT-enter-from {
  left: 100%;
}

.RIGHT-enter-to {
  left: 0%;
}

.UP-enter-active,
.UP-leave-active {
  z-index: 99999;
  width: 100%;
  position: absolute;
  transition: all 10s ease-in-out;
}

.UP-enter-from {
  z-index: 99999;
  bottom: -100%;
}

.UP-enter-to {
  z-index: 99999;
  bottom: 0%;
}

.modal-enter-from {
  transform: translateY(100%);
}

.modal-enter-to {
  transition: all 0.3s ease-in-out;
  transform: translateY(0%);
}

.modal-leave-to {
  transition: all 0.3s ease-in-out;
  transform: translateY(100%);
}


footer {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 80px;
  bottom: 0;
  left: 0;
  background: #FFFFFF;
  border-top: 1px solid #F2F3F5;
  border-radius: 0px;

  .autoScale {
    padding-top: 10px;
    width: 90%;
    display: flex;
    justify-content: space-between;

    a {
      width: 85px;
      text-decoration: none;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        height: 24px;
        height: 24px;
      }

      span {
        margin-top: 6px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #909090;
        transition: all 0.5s ease-in-out;
      }
    }

svg {
  transition: fill .3s ease-in-out;
}

svg path {
  -webkit-transition: fill .3s ease-in-out;
  -moz-transition: fill .3s ease-in-out;
  -o-transition: fill .3s ease-in-out;
  transition: fill .3s ease-in-out;
}
    .activate{
      fill: #FF7212;
    }
  }
}
.settingPage{
  background: white;
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 9;
  top:0;
  left: 0;
  .header{
    height: 48px;
    display: flex;
    margin-left: 7px;
  }
  .title{
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: -0.02em;
    color: #191919;
    border-bottom: 1px solid #F2F3F5; 
    padding: 20px 0 10px 24px;
    text-align: left;

  }
  .viewMenu{
    padding: 20px 24px 0 24px;
    div{
      height: 47px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      .text{
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -0.02em;
        color: #505050;
      }
      .ver{
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: right;
        letter-spacing: -0.02em;
        color: #A0A3AB;
      }
    }
  }
  .bottomFixMenu{
    position:absolute;
    width: 100%;
    bottom: 0%;
    left: 0%; 
    .group{
      margin: 0 24px 50px 24px;
      div{
        margin-top: 12px;
        background: #F2F3F5;
        border-radius: 12px;
        padding: 13px 0;
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        color: #505050;
      }
    }
  }
}
.alarmPage{
  background: white;
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 9;
  top:0;
  left: 0;
  .fix{
    background: white;
    width: 100%;
    position: fixed;
  }
  .header{
    height: 48px;
    display: flex;
    margin-left: 7px;
  }
  .g-title{
    margin-left: 20px;
    text-align: left;
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: -0.02em;
    color: #191919;
  }
  .HR{
    margin-top: 9px;
    height: 1px;
    background: #F2F3F5;
  }
  .LOOPAREA{
    background: white;
    margin-top: 119px;
    .item{
      padding: 0 20px 44px 20px;
      display: flex;
      .right{
        width: 100%;
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .top{
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 4px;
          .status{
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: -0.02em;
            color: #505050;
          }
          .time{
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            text-align: right;
            letter-spacing: -0.02em;
            color: #A0A3AB;
          }
        }
        .bottom{
          text-align: left;
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.01em;
          color: #191919;
        }
      }
    }
  }
}
.modalBackground{
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10;
  background: rgba(25, 25, 25, 0.2);
}
.signoutModal{
  width: 100%;
  position: fixed;
  z-index: 3;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 11;
  .view{
    height: 100%;
    margin: 0 16px 24px 16px;
    background: #FFFFFF;
    border-radius: 30px;
    .title{
      margin: 24px 0 38px 24px;
      text-align: left;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: -0.02em;
      color: #191919;
    }
    .logoutbutton{

      margin: 0 20px 16px 20px;
      background: #FF9210;
      border-radius: 16px;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: #FFFFFF;
      padding: 18px 0;
    }
  }
}

.modal-background {
    z-index: 12;
    top: 0;
    position: absolute;
    background: rgba(25, 25, 25, 0.2);
    width: 100vw;
    height: 100vh;
  }

  .modal-background-enter-active,
  .modal-background-leave-active {
    transition: opacity 0.3s, transform 0.3s;
  }

  .modal-background-enter-from,
  .modal-background-leave-to {
    opacity: 0;
  }

  .modalLogout-enter-from {
    transform: translateY(100%);
  }

  .modalLogout-enter-to {
    transition: all 0.3s ease-in-out;
    transform: translateY(0%);
  }

  .modalLogout-leave-to {
    transition: all 0.3s ease-in-out;
    transform: translateY(100%);
  }
#checkoutModal{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 10;
  top:0;
  text-align: left;
  nav{
    display: flex;
  }
  .header{
    margin-top: 20px;
    margin-left: 24px;
    .big{
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 31px;
      letter-spacing: -0.02em;
      color: #191919;
    }
    .sub{
      margin-block: 8px;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.02em;
      color: #505050;
    }
  }
  .forms{
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    margin-top: 64px;
    .top{
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      line-height: 25px;
      letter-spacing: -0.02em;
      color: #303030;
      margin-bottom: 25px;
    }
    .texts{
      margin-left:26px;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.02em;
    }


    color: #767676;
    .container {
      margin-bottom: 27px;
      display: block;
      position: relative;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        width: 17px;
        height: 17px;
        border-radius: 50%;
        border: 1px solid #DDDDDD;
      }
    }
    .container input:checked ~ .checkmark {
      background: #FF7212;
      border: 1px solid #FF7212;
    }
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    .container input:checked ~ .checkmark:after {
      display: block;
    }
    .container .checkmark:after {
      width: 16px;
      height: 16px;
      border: none;
      border-radius: 50%;
      background-color: #FF7212;
      border: 1px solid #FF7212;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEwIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0zLjg4ODM2IDYuOTk5OThDMy43NDIwNiA3LjAwMDggMy41OTcwMyA2Ljk3MzY0IDMuNDYxNTkgNi45MjAwM0MzLjMyNjE1IDYuODY2NDIgMy4yMDI5OCA2Ljc4NzQ0IDMuMDk5MTEgNi42ODc2TDAuMTY0Mzk3IDMuODMyODFDMC4xMTIzMDEgMy43ODI3MyAwLjA3MDk2MjQgMy43MjMxNSAwLjA0Mjc0NDUgMy42NTc1MUMwLjAxNDUyNjUgMy41OTE4OCAwIDMuNTIxNDcgMCAzLjQ1MDM3QzAgMy4zNzkyNiAwLjAxNDUyNjUgMy4zMDg4OSAwLjA0Mjc0NDUgMy4yNDMyNUMwLjA3MDk2MjQgMy4xNzc2MSAwLjExMjMwMSAzLjExODAzIDAuMTY0Mzk3IDMuMDY3OTZMMC45NDI1NDkgMi4zMTM4NUMwLjk5NDIxOSAyLjI2MzM3IDEuMDU1NyAyLjIyMzMxIDEuMTIzNDMgMi4xOTU5NkMxLjE5MTE2IDIuMTY4NjIgMS4yNjM3OCAyLjE1NDU0IDEuMzM3MTYgMi4xNTQ1NEMxLjQxMDUzIDIuMTU0NTQgMS40ODMxOCAyLjE2ODYyIDEuNTUwOTEgMi4xOTU5NkMxLjYxODY0IDIuMjIzMzEgMS42ODAxMyAyLjI2MzM3IDEuNzMxOCAyLjMxMzg1TDMuNDk5MjggNC4wMTU5NkMzLjU0OTE4IDQuMDY3NDggMy42MDk0NSA0LjEwODU0IDMuNjc2MzYgNC4xMzY2MUMzLjc0MzI4IDQuMTY0NjkgMy44MTU0NCA0LjE3OTE5IDMuODg4MzYgNC4xNzkxOUMzLjk2MTI3IDQuMTc5MTkgNC4wMzM0MyA0LjE2NDY5IDQuMTAwMzUgNC4xMzY2MUM0LjE2NzI3IDQuMTA4NTQgNC4yMjc1MyA0LjA2NzQ4IDQuMjc3NDMgNC4wMTU5Nkw4LjI2ODE3IDAuMTU5MzE1QzguMzE5ODQgMC4xMDg4MyA4LjM4MTMyIDAuMDY4NzY5MSA4LjQ0OTA1IDAuMDQxNDIzM0M4LjUxNjc5IDAuMDE0MDc3NiA4LjU4OTQ0IDAgOC42NjI4MSAwQzguNzM2MTggMCA4LjgwODggMC4wMTQwNzc2IDguODc2NTMgMC4wNDE0MjMzQzguOTQ0MjYgMC4wNjg3NjkxIDkuMDA1NzUgMC4xMDg4MyA5LjA1NzQyIDAuMTU5MzE1TDkuODM1NTQgMC45MTM0MTdDOS44ODc2MyAwLjk2MzQ5IDkuOTI5MDQgMS4wMjMwNyA5Ljk1NzI2IDEuMDg4NzFDOS45ODU0NyAxLjE1NDM1IDEwIDEuMjI0NzIgMTAgMS4yOTU4M0MxMCAxLjM2NjkzIDkuOTg1NDcgMS40MzczNCA5Ljk1NzI2IDEuNTAyOThDOS45MjkwNCAxLjU2ODYxIDkuODg3NjMgMS42MjgyIDkuODM1NTQgMS42NzgyN0w0LjY3NzYgNi42ODc2QzQuNTczNzMgNi43ODc0NCA0LjQ1MDU2IDYuODY2NDIgNC4zMTUxMiA2LjkyMDAzQzQuMTc5NjggNi45NzM2NCA0LjAzNDY1IDcuMDAwOCAzLjg4ODM2IDYuOTk5OThaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K);
      content: "";
    }
  }
  .guard{
    padding: 0 20px 40px 20px;
  }
  .buttonGroup{
    display: flex;
    flex-direction: row;
    width: 100%;
    .cancel{
      background: #F2F3F5;
      border-radius: 16px;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      padding: 18px 0 17px 0;
      color: #767676;
      width: 100%;
    }
    .doCheckout{
      margin-left: 10px;
      background: #F26346;
      border-radius: 16px;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      padding: 18px 0 17px 0;
      color: #FFFFFF;
      width: 100%;
    }
  }
}
.checkoutDoneModal{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 10;
  top: 0;
  align-items: center;
  .guard{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .title{
    margin-top: 23px;
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    text-align: center;
    color: #191919;
  }
  .sub{
    margin-top: 16px;
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.02em;

    color: #A0A3AB;

  }
  .ButtonGroup{
    width: 100%;
    .Done{
      margin: 0 24px 36px 24px;
      background: #FF7212;
      border-radius: 16px;
      padding: 16px 0;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height */

      text-align: center;

      color: #FFFFFF;

    }
  }
}
.requestAnimation-enter-active,
.requestAnimation-leave-active {
  width: 100%;
  position: absolute;
  transition: all 0.3s ease-in-out;
  left: 0%;
  z-index: 15;
}
.requestAnimation-enter-from {
  left: 100%;
}
.requestAnimation-enter-to {
  left: 0%;
}
.requestAnimation-leave-to {
  left: 100%;
}
</style>