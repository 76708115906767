<template>
  <div>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_66_1414)">
        <path d="M9.5 3.5C9.5 2.94772 9.05228 2.5 8.5 2.5C7.94772 2.5 7.5 2.94772 7.5 3.5V6.5C7.5 7.05228 7.94772 7.5 8.5 7.5C9.05228 7.5 9.5 7.05228 9.5 6.5V3.5Z" fill="#CDD0D8"/>
        <path d="M17.5 3.5C17.5 2.94772 17.0523 2.5 16.5 2.5C15.9477 2.5 15.5 2.94772 15.5 3.5V6.5C15.5 7.05228 15.9477 7.5 16.5 7.5C17.0523 7.5 17.5 7.05228 17.5 6.5V3.5Z" fill="#CDD0D8"/>
        <path d="M5.5 21H19.5C20.6 21 21.5 20.1 21.5 19V10H3.5V19C3.5 20.1 4.4 21 5.5 21Z" fill="#CDD0D8"/>
        <path d="M3.5 7V10H21.5V7C21.5 5.9 20.6 5 19.5 5H5.5C4.4 5 3.5 5.9 3.5 7Z" fill="#CDD0D8"/>
        <path d="M11.5002 16.51C11.2402 16.51 10.9902 16.41 10.7902 16.22L8.14023 13.57C7.94023 13.37 7.94023 13.06 8.14023 12.86L8.85023 12.15C9.05023 11.95 9.36023 11.95 9.56023 12.15L11.1502 13.74C11.3502 13.94 11.6602 13.94 11.8602 13.74L15.4502 10.15C15.6502 9.95 15.9602 9.95 16.1602 10.15L16.8702 10.86C17.0702 11.06 17.0702 11.37 16.8702 11.57L12.2202 16.22C12.0202 16.42 11.7702 16.51 11.5102 16.51H11.5002Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_66_1414">
          <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </svg>


    <transition name="transitionAnimationOpacity">
      <svg v-if="activationValue" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="activateSVG">
        <g clip-path="url(#clip0_66_1462)">
          <path d="M9.5 3.5C9.5 2.94772 9.05228 2.5 8.5 2.5C7.94772 2.5 7.5 2.94772 7.5 3.5V6.5C7.5 7.05228 7.94772 7.5 8.5 7.5C9.05228 7.5 9.5 7.05228 9.5 6.5V3.5Z" fill="#FF7212"/>
          <path d="M17.5 3.5C17.5 2.94772 17.0523 2.5 16.5 2.5C15.9477 2.5 15.5 2.94772 15.5 3.5V6.5C15.5 7.05228 15.9477 7.5 16.5 7.5C17.0523 7.5 17.5 7.05228 17.5 6.5V3.5Z" fill="#FF7212"/>
          <path d="M5.5 21H19.5C20.6 21 21.5 20.1 21.5 19V10H3.5V19C3.5 20.1 4.4 21 5.5 21Z" fill="#FF7212"/>
          <path d="M3.5 7V10H21.5V7C21.5 5.9 20.6 5 19.5 5H5.5C4.4 5 3.5 5.9 3.5 7Z" fill="#FF7212"/>
          <path d="M11.5002 16.51C11.2402 16.51 10.9902 16.41 10.7902 16.22L8.14023 13.57C7.94023 13.37 7.94023 13.06 8.14023 12.86L8.85023 12.15C9.05023 11.95 9.36023 11.95 9.56023 12.15L11.1502 13.74C11.3502 13.94 11.6602 13.94 11.8602 13.74L15.4502 10.15C15.6502 9.95 15.9602 9.95 16.1602 10.15L16.8702 10.86C17.0702 11.06 17.0702 11.37 16.8702 11.57L12.2202 16.22C12.0202 16.42 11.7702 16.51 11.5102 16.51H11.5002Z" fill="white"/>
        </g>
        <defs>
          <clipPath id="clip0_66_1462">
            <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
          </clipPath>
        </defs>
      </svg>
    </transition>
    <span ref="text">예약내역</span>
  </div>
</template>

<script>
export default {
  props: ['activationValue'],
  watch: {
    activationValue(newValue){
      if(newValue) {
        this.$refs.text.classList.add('activate');
      } else {
        this.$refs.text.classList.remove('activate');
      }
    }
  }
};


</script>

<style>
span{
  transition: color .3s ease-in-out;
}
.activate {
  color: #FF7212!important;
}
.activateSVG{
  position: absolute;
}
.deactivate{
  color: #CDD0D8;
}
</style>