export default {
  namespaced: true,
  state() {
    return {
      consumer_consumerrequest: [],
      owner_marketinfo: [],
      owner_responsemessage: [],
      users_fcm: [],
      users_user: [],
    };
  },
  mutations: {
    saveConsumerrequest(state, Data) {
      state.consumer_consumerrequest = Data;
    },
    saveMarketInfo(state, Data) {
      state.owner_marketinfo = Data;
    },
    saveResponsemessage(state, Data) {
      state.owner_responsemessage = Data;
    },
    saveFCM(state, Data) {
      state.users_fcm = Data;
    },
    saveUser(state, Data) {
      state.users_user = Data;
    },
  },
  actions: {
    async fetchData({ commit }) {
      const response = await fetch("http://127.0.0.1:5000/process", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const responseData = await response.json();
      commit("saveConsumerrequest", JSON.parse(responseData["consumer_consumerrequest"]));
      commit("saveMarketInfo", JSON.parse(responseData["owner_marketinfo"]));
      commit("saveResponsemessage", JSON.parse(responseData["owner_responsemessage"]));
      commit("saveFCM", JSON.parse(responseData["users_fcm"]));
      commit("saveUser", JSON.parse(responseData["users_user"]));
    },
  },
  getters: {
    loadConsumerrequest(state) {
      return state.consumer_consumerrequest;
    },
    loadMarketInfo(state) {
      return state.owner_marketinfo;
    },
    loadResponsemessage(state) {
      return state.owner_responsemessage;
    },
    loadFCM(state) {
      return state.users_fcm;
    },
    loadUser(state) {
      return state.users_user;
    },
  },
};
