<template>
  <div>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_66_1444)">
        <path d="M12.5 13C7 13 4.5 15.84 4.5 18.21C4.5 20.58 4.5 22 12.5 22C20.5 22 20.5 20.58 20.5 18.21C20.5 15.84 18 13 12.5 13Z" fill="#CDD0D8"/>
        <path d="M12.5 12C14.9853 12 17 9.98528 17 7.5C17 5.01472 14.9853 3 12.5 3C10.0147 3 8 5.01472 8 7.5C8 9.98528 10.0147 12 12.5 12Z" fill="#CDD0D8"/>
      </g>
      <defs>
        <clipPath id="clip0_66_1444">
         <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </svg>



    <transition name="transitionAnimationOpacity">
      <svg v-if="activationValue" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="activateSVG">
        <g clip-path="url(#clip0_66_1492)">
          <path d="M12.5 13C7 13 4.5 15.84 4.5 18.21C4.5 20.58 4.5 22 12.5 22C20.5 22 20.5 20.58 20.5 18.21C20.5 15.84 18 13 12.5 13Z" fill="#FF7212"/>
          <path d="M12.5 12C14.9853 12 17 9.98528 17 7.5C17 5.01472 14.9853 3 12.5 3C10.0147 3 8 5.01472 8 7.5C8 9.98528 10.0147 12 12.5 12Z" fill="#FF7212"/>
        </g>
        <defs>
          <clipPath id="clip0_66_1492">
            <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
          </clipPath>
        </defs>
      </svg>
    </transition>
    <span ref="text">마이페이지</span>
  </div>
</template>

<script>
export default {
  props: ['activationValue'],
  watch: {
    activationValue(newValue){
      if(newValue) {
        this.$refs.text.classList.add('activate');
      } else {
        this.$refs.text.classList.remove('activate');
      }
    }
  }
};


</script>

<style>
span{
  transition: color .3s ease-in-out;
}
.activate {
  color: #FF7212!important;
}
.activateSVG{
  position: absolute;
}
.deactivate{
  color: #CDD0D8;
}
</style>