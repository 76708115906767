<template>
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/reset-css@5.0.1/reset.min.css">
  <link rel="stylesheet" as="style" crossorigin href="https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard.css" />
  <router-view></router-view>
  <div id="FFEMAIL" style="display: none;">{{ username }}</div>
</template>

<script>
export default {
  data() {
    return {
      username: ''
    }
  },
  watch: {
    '$store.getters.reLoadUserInfo.username': function(newVal) {
      this.username = newVal
    }
  },
  created() {
    this.username = this.$store.getters.reLoadUserInfo2;
    this.$store.dispatch('tryLogin');
    document.documentElement.style.setProperty('--window-width', `${window.innerWidth}px`);
    document.documentElement.style.setProperty('--window-height', `${window.innerHeight}px`);
    
  },
  methods: {
    
  }
}
</script>

<style>
#app {
  -webkit-tap-highlight-color:transparent;
  font-family: Pretendard;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#viewport {
  padding-top: 48px;
}
::-webkit-scrollbar {
  display: none;
}
.transitionAnimationOpacity-enter-active,
.transitionAnimationOpacity-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.transitionAnimationOpacity-enter-from,
.transitionAnimationOpacity-leave-to {
  opacity: 0;
}
.transitionAnimationUp-enter-from {
  transform: translateY(100%);
}

.transitionAnimationUp-enter-to {
  transition: all 0.3s ease-in-out;
  transform: translateY(0px);
}

.transitionAnimationUp-leave-to {
  transition: all 0.3s ease-in-out;
  transform: translateY(100%);
}
</style>
