export default {
  async RequestListSelectedBid(context, payload) {
    console.log(payload);
    context.commit('setRequestListSelectedBid', payload);
  },
  async sumbitBid(context, payload) {
    const response = await fetch('https://'+context.rootState.checkApiAdress+'/api/v1/owner/request/response', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization' : 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify({
        benefitTags : payload.item,
        message : payload.message,
        requestid : payload.ids,
      })
    });
    let responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || '인증하지 못하였습니다.'
      );
      throw error;
    }
    context.commit('setRequestListSelectedBid', null);
  },
  async loadShopProfile(context){
    fetch('https://'+this.state.checkApiAdress+'/api/v1/users/owner/market-profile', {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
    .then(response => response.json())
    .then(data => {context.commit('setShopInfo', data.market_info)})
    .catch(error => console.error(error))
  }
}