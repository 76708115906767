<template>
  <div>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_66_1427)">
      <path d="M12.21 2.71005L3.91 8.70005C3.65 8.89005 3.5 9.19005 3.5 9.51005V20.5C3.5 20.7801 3.72 21 4 21H9C9.28 21 9.5 20.7801 9.5 20.5V17.13C9.5 15.49 10.77 14.05 12.41 14.01C14.05 13.97 15.5 15.32 15.5 17.01V20.51C15.5 20.7901 15.72 21.01 16 21.01H21C21.28 21.01 21.5 20.7901 21.5 20.51V9.51005C21.5 9.19005 21.35 8.89005 21.09 8.70005L12.79 2.71005C12.62 2.58005 12.38 2.58005 12.2 2.71005H12.21Z" fill="#CDD0D8"/>
      </g>
      <defs>
        <clipPath id="clip0_66_1427">
          <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </svg>
    <transition name="transitionAnimationOpacity">
      <svg v-if="activationValue" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="activateSVG">
        <g clip-path="url(#clip0_66_1403)">
          <path d="M12.21 2.71005L3.91 8.70005C3.65 8.89005 3.5 9.19005 3.5 9.51005V20.5C3.5 20.7801 3.72 21 4 21H9C9.28 21 9.5 20.7801 9.5 20.5V17.13C9.5 15.49 10.77 14.05 12.41 14.01C14.05 13.97 15.5 15.32 15.5 17.01V20.51C15.5 20.7901 15.72 21.01 16 21.01H21C21.28 21.01 21.5 20.7901 21.5 20.51V9.51005C21.5 9.19005 21.35 8.89005 21.09 8.70005L12.79 2.71005C12.62 2.58005 12.38 2.58005 12.2 2.71005H12.21Z" fill="#FF7212"/>
        </g>
        <defs>
          <clipPath id="clip0_66_1403">
            <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
          </clipPath>
        </defs>
      </svg>
    </transition>

    <span ref="text">홈</span>
  </div>
</template>

<script>
export default {
  props: ['activationValue'],
  watch: {
    activationValue(newValue){
      if(newValue) {
        this.$refs.text.classList.add('activate');
      } else {
        this.$refs.text.classList.remove('activate');
      }
    }
  }
};


</script>

<style>
span{
  transition: color .3s ease-in-out;
}
.activate {
  color: #FF7212!important;
}
.activateSVG{
  position: absolute;
}
.deactivate{
  color: #CDD0D8;
}
</style>