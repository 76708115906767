<template>
  <div>
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_66_1408)">
        <path d="M20.48 4H4.51998C3.64998 4 3.17998 5 3.74998 5.64L8.72998 10.73C8.88998 10.89 9.12998 10.93 9.32998 10.82L15.55 7.41C15.71 7.32 15.91 7.37 16.01 7.52C16.11 7.66 16.08 7.86 15.94 7.97L10.25 12.31C10.09 12.43 10.02 12.63 10.07 12.82L11.76 20.28C12 21.16 13.23 21.27 13.65 20.46L21.39 5.44C21.73 4.78 21.24 4.01 20.48 4.01V4Z" fill="#CDD0D8"/>
      </g>
      <defs>
      <clipPath id="clip0_66_1408">
        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
      </clipPath>
      </defs>
    </svg>

  <transition name="transitionAnimationOpacity">
    <svg v-if="activationValue" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="activateSVG">
      <g clip-path="url(#clip0_66_1432)">
        <path d="M20.48 4H4.51998C3.64998 4 3.17998 5 3.74998 5.64L8.72998 10.73C8.88998 10.89 9.12998 10.93 9.32998 10.82L15.55 7.41C15.71 7.32 15.91 7.37 16.01 7.52C16.11 7.66 16.08 7.86 15.94 7.97L10.25 12.31C10.09 12.43 10.02 12.63 10.07 12.82L11.76 20.28C12 21.16 13.23 21.27 13.65 20.46L21.39 5.44C21.73 4.78 21.24 4.01 20.48 4.01V4Z" fill="#FF7212"/>
      </g>
      <defs>
      <clipPath id="clip0_66_1432">
        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
      </clipPath>
      </defs>
    </svg>
  </transition>

    <span ref="text">요청현항</span>
  </div>
</template>

<script>
export default {
  props: ['activationValue'],
  watch: {
    activationValue(newValue){
      if(newValue) {
        this.$refs.text.classList.add('activate');
      } else {
        this.$refs.text.classList.remove('activate');
      }
    }
  }
};


</script>

<style>
span{
  transition: color .3s ease-in-out;
}
.activate {
  color: #FF7212!important;
}
.activateSVG{
  position: absolute;
}
.deactivate{
  color: #CDD0D8;
}
</style>