export default {
  setRequestDetail(state, payload) {
    state.requestDetail = payload;
  },
  setShopInfo(state, payload) {
    console.log(payload);
    state.shopInfo = payload;
    console.log(state.shopInfo);
  },
  updateRequestList: (state, requestList) => {
    state.requestList = requestList;
  },
  loadRequestDetail: (state, requestDetail) => {
    state.requestDetail = requestDetail;
  },
};