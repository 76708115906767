let socket = null;
export default {
  async loadRequest(context) {
    socket && socket.close();
    console.log(context);
    socket = new WebSocket('wss://'+context.rootState.checkApiAdress+'/ws/consumer/my-request/token='+ localStorage.getItem('token'));
    socket.onmessage = function (event) {
      const requestList = JSON.parse(event.data);
      context.commit('updateRequestList', requestList);
    };
  },
  async loadRequestDetail(context, payload) {
    socket && socket.close();
    socket = new WebSocket('wss://'+context.rootState.checkApiAdress+'/'+ payload+'/');
    socket.onmessage = function (event) {
      const requestList = JSON.parse(event.data);
      context.commit('loadRequestDetail', requestList);
    };
  },
  async setShopInfo(context, payload) {
    context.commit('setShopInfo', payload);
  },
  async acceptBid(context, payload) {
    const response = await fetch('https://'+context.rootState.checkApiAdress+'/api/v1/consumer/request/accept/'+payload, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization' : 'Bearer ' + localStorage.getItem('token')
      },
    });
    let responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || '인증하지 못하였습니다.'
      );
      throw error;
    }
  },
  async uploadRequest(context, payload) {
    console.log(context, payload);
    const response = await fetch('https://'+context.rootState.checkApiAdress+'/api/v1/consumer/request/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization' : 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify(payload)
    });
    let responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || '인증하지 못하였습니다.'
      );
      throw error;
    }
  }
}

// this.$store.dispatch('consumer/loadRequestDetail', this.$route.params.id)
// console.log('load dispatch.')
// this.savedData = this.$store.getters['consumer/getRequestDetail'];