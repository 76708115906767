<template>
  <div class="base-region">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1936_922)">
      <path d="M13.3332 6.66665C13.3332 3.71998 10.9465 1.33331 7.99984 1.33331C5.05317 1.33331 2.6665 3.71998 2.6665 6.66665C2.6665 6.83331 2.70317 7.23998 2.70317 7.27665C2.94984 10.93 6.45984 13.6233 7.6265 14.42C7.85317 14.5733 8.14984 14.5733 8.37317 14.42C9.53984 13.6233 13.0498 10.93 13.2965 7.27665C13.2965 7.23998 13.3332 6.83331 13.3332 6.66665Z" fill="#A0A3AB"/>
      <path d="M8 8.66669C9.10457 8.66669 10 7.77126 10 6.66669C10 5.56212 9.10457 4.66669 8 4.66669C6.89543 4.66669 6 5.56212 6 6.66669C6 7.77126 6.89543 8.66669 8 8.66669Z" fill="#F2F3F5"/>
      </g>
      <defs>
      <clipPath id="clip0_1936_922">
      <rect width="16" height="16" fill="white"/>
      </clipPath>
      </defs>
    </svg>
    <span>{{ regionName }}</span>
  </div>
</template>

<script>
export default {
  props:['regionName']
}
</script>
<style lang="scss" scoped>
.base-region{
  padding: 4px 8px 4px 6px;
  border-radius: 12px;
  background: #F2F3F5;
  display: flex;
  span{
    margin-left: 2px;
    color: #8B8E96;
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.26px;
  }
}
</style>
