import { createRouter, createWebHistory } from 'vue-router';

import store from './store/index.js';

import consumerHandler from "@/pages/consumer/transition-handle.vue";


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/consumer/home'   // redirect 설정
    },
    { 
      path: '/consumer', 
      component: consumerHandler, 
      meta: { requiresAuth: true },
      children: [
        { path: 'home', component: () => import('@/pages/consumer/home-page.vue')},  // 사용자 홈
        { path: 'requestlist', component: () => import('@/pages/consumer/request-list.vue') },  // 사용자 요청내역
        { path: 'requestlistdetail/:id', component: () => import('@/pages/consumer/request-list-detail.vue') },  // 사용자 요청내역 자세히보기
        { path: 'shopinfo', component: () => import('@/pages/consumer/shop-info.vue'), name:'shopinfo' }, // 사용자 상점정보
        { path: 'biddone', component: () => import('@/pages/consumer/bid-done.vue') },  // 사용자 사용자 낙찰완료
        { path: 'makerequest', component: () => import('@/pages/consumer/make-request.vue') },  // 사용자 요청서 작성
        { path: 'uploaddone', component: () => import('@/pages/consumer/upload-done.vue') },  // 사용자 요청서 업로드 완료
        { path: 'confirmlist', component: () => import('@/pages/consumer/confirm-list.vue') }, // 사용자 낙찰내역
        { path: 'mypage', component: () => import('@/pages/consumer/my-page.vue') } // 사용자 마이페이지
      ]
    },
    {
      path: '/owner',
      meta: { requiresAuth: true },
      component: () => import("@/pages/owner/transition-handle.vue"), // 사장님 효과 핸들러
      children: [
        { path: 'requestlist', component: () => import("@/pages/owner/request-list.vue") }, // 사장님 요청함
        { path: 'makebid', component: () => import("@/pages/owner/make-bid.vue") }, // 사장님 비드작성
        { path: 'makebiddone', component: () => import("@/pages/owner/make-bid-done.vue") }, // 사장님 비드작성완료
        { path: 'waitinglist', component: () => import("@/pages/owner/waiting-list.vue") }, // 사장님 대기내역
        { path: 'waitinglistdone', component: () => import("@/pages/owner/waiting-list-done.vue") }, // 사장님 대기내역에서 낙찰확정
        { path: 'bidconfirm', component: () => import("@/pages/owner/new_bid_confirm.vue") }, // 사장님 낙찰확정
        { path: 'mypage', component: () => import("@/pages/owner/my-page.vue") } // 사장님 마이페이지
      ]
    },
    {
      path: '/admin',
      component: () => import("@/pages/admin/MainCore.vue")
    },
    {
      path: '/auth',
      meta: { requiresAuth: false },
      component: () => import("@/pages/auth/transition-handle.vue"),
      children: [
        { path: 'login', component: () => import("@/pages/auth/userLogin.vue") },
        { path: 'signup', component: () => import("@/pages/auth/accountSignup.vue") },
      ]
    },
    
  ]
});

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token');
  console.log(store.state.auth.user)
  const isBoss = store.state.auth.user && store.state.auth.user.isBoss;

  if (to.path === '/auth/signup/') {
    next();
  } else if (!token && to.path !== '/auth/login') {
    next('/auth/login');
  } else if (token && to.path === '/auth/login') {
    if (isBoss) {
      await store.dispatch('owner/loadShopProfile');
      next('/owner/requestlist');
    } else {
      next('/consumer/home');
    }
  } else if (to.path.startsWith('/owner') && !isBoss) {
    next('/consumer/home');
  } else if (to.path.startsWith('/consumer') && isBoss) {
    await store.dispatch('owner/loadShopProfile');
    next('/owner/requestlist');
  } else {
    next();
  }
});
export default router;


