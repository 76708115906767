import { createStore } from 'vuex';

import authModule from './modules/auth/index.js';
import consumerModule from './modules/consumer/index.js'
import ownerModule from './modules/owner/index.js'
import globalModule from './modules/global/index.js'
import adminModule from './modules/admin/index.js'



const store = createStore({
  namespaced: true,
  modules: {
    auth: authModule,
    consumer: consumerModule,
    owner: ownerModule,
    global: globalModule,
    admin: adminModule
  },
  state() {
    return {
      checkApiAdress: 'api.gagenow.com',
      // checkApiAdress: 'gagenowpreview.com',
      // checkApiAdress: 'gagenowspringboot.gagenowpreview.com',
      version: '2.1.1'
    };
  },
});

export default store;