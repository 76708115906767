<template>
  <div class="global-button">
    <div v-if="buttonStyleType == 'double-select'"></div>
    <div class="cancel-select" v-else-if="buttonStyleType == 'cancel-select'">
      <div class="cancel-button" @click="emitFirstActions">취소</div>
      <div class="action-button" @click="emitSecondActions">{{ buttonTextObject[0] }}</div>
    </div>
    <div class="single-select" v-else-if="buttonStyleType == 'single-select'" :style="customStyles">
      <div class="action-button" @click="emitFirstActions">{{ buttonTextObject[0] }}</div>
    </div>
    <div class="grey-double-select" v-else-if="buttonStyleType == 'grey-double-select'">
      <div class="activate-button" v-if="disabledObject[0] == 'true'" @click="emitFirstActions">{{ buttonTextObject[0] }}</div>
      <div class="disabled-button" v-else>{{ buttonTextObject[0] }}</div>
      <div class="activate-button" v-if="disabledObject[1] == 'true'" @click="emitSecondActions">{{ buttonTextObject[1] }}</div>
      <div class="disabled-button" v-else>{{ buttonTextObject[1] }}</div>
    </div>
  </div>
</template>

<script> 
export default {
  props: {
    buttonStyleType : {
      type: String,
      required: true
    },
    buttonTextObject: {
      type: Object,
      required: true
    },
    customStyles: {
      type: Object,
      required: false
    },
    disabledObject: {
      type: Object,
      required: false
    }
  },
  methods: {
    emitFirstActions(){
      this.$emit('firstActions');
    },
    emitSecondActions(){
      this.$emit('secondActions')
    }
  }
}
</script>

<style lang="scss" scoped>
.global-button{
  .cancel-select{
    display: flex;
    justify-content: space-between;
    width: 100%;
    div{
      flex: 1;
    }
    .cancel-button{
      border-radius: 16px;
      background: rgba(255, 146, 16, 0.16);
      margin-right: 10px;
      color: var(--secondary, #FF9210);
      text-align: center;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 18px 16px 17px 16px;
    }
    .action-button{
      border-radius: 16px;
      background: #FF9210;
      color: #FFF;
      text-align: center;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 18px 16px 17px 16px;
    }
  }
  .single-select{
    background: white;
    width: calc(var(--window-width) - 48px );
    padding: 8px 24px 36px 24px;
    .action-button{
      padding: 16px;
      border-radius: 16px;
      background: #FF7212;
      color: #FFF;
      text-align: center;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .grey-double-select{
    display: flex;
    justify-content: space-between;
    div{
      border-radius: 12px;
      flex: 1;
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
    .activate-button, .disabled-button{
      padding: 13px 0;
      display: inline-block;
      background: #F2F3F5;
      color: #505050;
      text-align: center;
      font-family: Pretendard;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
</style>