// store/modules/globals.js
const state = {
  modalOpenValue: false,
  alertModalOpenValue: false,
};

const mutations = {
  SET_MODAL_OPEN_VALUE(state, value) {
    state.modalOpenValue = value;
  },
  SET_ALARM_OPEN_VALUE(state, value) {
    state.alertModalOpenValue = value;
  },
};

const actions = {
  setModalOpenValue({ commit }, value) {
    commit("SET_MODAL_OPEN_VALUE", value);
  },
  setAlarmOpenValue({ commit }, value) {
    commit("SET_ALARM_OPEN_VALUE", value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
