<template>
  <div class="card-gray">
    <slot></slot>
  </div>
</template>
    
<style scoped>
.card-gray {
  padding: 12px 16px; 
  background: #F2F3F5;
  border-radius: 24px;
}
</style>
