<template>
  <div class="card">
    <slot></slot>
  </div>
</template>
  
<style scoped>
.card {
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px #F2F2F2;
  border-radius: 24px;
}
</style>