import SecureStorage from "secure-web-storage";

var CryptoJS = require("crypto-js");
var SECRET_KEY = 'my secret key';
var secureStorage = new SecureStorage(localStorage, {
  hash: function hash(key) {
      key = CryptoJS.SHA256(key, SECRET_KEY);

      return key.toString();
  },
  encrypt: function encrypt(data) {
      data = CryptoJS.AES.encrypt(data, SECRET_KEY);

      data = data.toString();

      return data;
  },
  decrypt: function decrypt(data) {
      data = CryptoJS.AES.decrypt(data, SECRET_KEY);

      data = data.toString(CryptoJS.enc.Utf8);

      return data;
  }
});

export default {
  userId(state) {
    return state.userId;
  },
  token(state) {
    return state.token;
  },
  userData(state){
    return state.user;
  },
  isAuthenticated(state) {
    console.log('loadtoken :'+state.token);
    return !!state.token;
  },
  didAutoLogout(state) {
    return state.didAutoLogout;
  },
  checkApiAdress(state, getters, rootState) {
    return rootState.apiServerAdress;
  },
  getEmail(state) {
    return state.signupData.email
  },
  getPassword(state) {
    return state.signupData.password
  },
  getPhone(state) {
    return state.signupData.phoneNumber
  }, 
  reLoadUserInfo(){
    if(JSON.parse(secureStorage.getItem('UserData'))){
      return JSON.parse(secureStorage.getItem('UserData'));
    }
  },
  reLoadUserInfo2(){
    if(JSON.parse(secureStorage.getItem('UserData'))){
      return JSON.parse(secureStorage.getItem('UserData')).username;
    }
  }
};